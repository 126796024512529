import { Stack, TextField, Text, Icon, Dropdown, Spinner, SpinnerSize } from "@fluentui/react";
// import { Flex, Loader } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import { TeamsAuth } from "../../utils/TeamsAuth";
import './Login.scss';

export interface LoginProps 
{

}

export const Login: React.FunctionComponent<LoginProps> = (props) => 
{
    useEffect(() => 
    {
        microsoftTeams.initialize();
        microsoftTeams.getContext(context => 
        {
            if(window.location.search.length > 0)
            {
                const params = new URLSearchParams(window.location.search.substring(1));
                let scopes = [];

                if(params.get('graphScopes'))
                    scopes.push(...params.get('graphScopes').split(' '));
                if(params.get('spScopes'))
                    scopes.push(...params.get('spScopes').split(' '));
                if(params.get('scopes'))
                    scopes.push(...params.get('scopes').split(' '));

                const tenantId = context.tid;
                const loginHint = context.loginHint;

                if(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
                {
                    setTimeout(() => {
                        if(params.get('mode') === 'consent')
                            TeamsAuth.redirectToConsentPage(tenantId, scopes, loginHint);
                        else
                            TeamsAuth.redirectToAuthorizePage(tenantId);
                    }, 5000);
                }
                else
                {
                    if(params.get('mode') === 'consent')
                        TeamsAuth.redirectToConsentPage(tenantId, scopes, loginHint);
                    else
                        TeamsAuth.redirectToAuthorizePage(tenantId);
                }
            }
            else
            {
                alert('Error: No scopes specified. Please specify graphScopes and/or spScopes in the query string');
                console.error('No scopes specified. Please specify graphScopes and/or spScopes in the query string');
            }
        });
    }, []);

    return (
        <div className="page-login">
            <Stack className="login-stack">
                <Spinner size={SpinnerSize.large} />
                <Text variant="large">Signing in</Text>
            </Stack>
            {/* <Flex className="login-stack" hAlign="center">
                <Loader label="Signing in" />
            </Flex> */}
        </div>
    );
};

export default Login;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { initializeIcons, ThemeProvider, loadTheme } from '@fluentui/react';
import * as microsoftTeams from "@microsoft/teams-js";
import ConnectorConfigurationPage from './pages/ConnectorConfigurationPage';
import Login from './pages/auth/Login';
import Callback from './pages/auth/Callback';
import './index.scss';
import Vars from './Vars';
import InstructionsTab from './pages/InstructionsTab';

initializeIcons();

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Switch>
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/callback" component={Callback} />
                <Route path="/connector/config" component={ConnectorConfigurationPage} />
                <Route path="/personal" component={InstructionsTab} />
                <Redirect path="/" to="/connector/config" />
            </Switch>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

microsoftTeams.initialize();
microsoftTeams.getContext(context => 
{
    if(context.theme === 'dark')
    {
        loadTheme({
            palette: {
                "themePrimary": "#0078d4",
                "themeLighterAlt": "#eff6fc",
                "themeLighter": "#deecf9",
                "themeLight": "#c7e0f4",
                "themeTertiary": "#71afe5",
                "themeSecondary": "#2b88d8",
                "themeDarkAlt": "#106ebe",
                "themeDark": "#005a9e",
                "themeDarker": "#004578",
                "neutralLighterAlt": "#1e1e1e",
                "neutralLighter": "#1d1d1d",
                "neutralLight": "#1c1c1c",
                "neutralQuaternaryAlt": "#1a1a1a",
                "neutralQuaternary": "#191919",
                "neutralTertiaryAlt": "#181818",
                "neutralTertiary": "#c8c8c8",
                "neutralSecondary": "#d0d0d0",
                "neutralPrimaryAlt": "#dadada",
                "neutralPrimary": "#ffffff",
                "neutralDark": "#f4f4f4",
                "black": "#f8f8f8",
                "white": "#1f1f1f"
            }
        });
    }
});

console.log(`Client ID: ${Vars.CLIENT_ID}`);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

import * as microsoftTeams from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import DataService from "../data/DataService";
import GraphClient from "../data/GraphClient";
import { TeamsAuth } from "../utils/TeamsAuth";
import './ConnectorConfigurationPage.scss';
import { Site } from "@microsoft/microsoft-graph-types"
import Vars from "../Vars";
import { Text, Stack, Spinner, Dropdown, SpinnerSize, ComboBox, IComboBoxOption, TagPicker, ITag, Label } from "@fluentui/react";
import { SPRest } from "@pnp/sp/presets/core";

interface Tokens
{
    graph: string;
    graphRefresh: string;
    sp: string;
    spRefresh: string;
}

export interface ConnectorConfigurationProps 
{

}

// This page is used to configure the connector when being added to a channel.

export const ConnectorConfigurationPage: React.FunctionComponent<ConnectorConfigurationProps> = (props) => 
{
    const [ context, setContext ] = useState<microsoftTeams.Context>(null);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ submitting, setSubmitting ] = useState<boolean>(false);
    const [ sites, setSites ] = useState<Site[]>([]);
    const [ tokens, setTokens ] = useState<Tokens>(null);
    const [ selectedSite, setSelectedSite ] = useState<Site>(null);
    const [ editMessage, setEditMessage ] = useState<string>(null);
    const [ errorMessage, setErrorMessage ] = useState<string>(null);

    useEffect(() => 
    {
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => 
        {
            setContext(context);

            TeamsAuth.ssoAuthenticate().then(async token => 
            {
                try
                {
                    const spScopes = [`https://${context.teamSiteDomain}/.default`, 'offline_access']
                    const response = await TeamsAuth.getAccessTokens(token, Vars.GRAPH_SCOPES, spScopes);

                    GraphClient.initialize(response.graph.access_token);

                    setTokens({
                        graph: response.graph.access_token,
                        graphRefresh: response.graph.refresh_token,
                        sp: response.sp.access_token,
                        spRefresh: response.sp.refresh_token
                    });

                    const sites = await GraphClient.current().sites();
                    setSites(sites);

                    microsoftTeams.settings.getSettings(settings => 
                    {
                        if(settings.entityId)
                        {
                            setEditMessage(`Already connected to ${settings['configName']}. Click 'remove' to delete this connection.`);
                        }

                        setLoading(false);    
                        microsoftTeams.appInitialization.notifySuccess();
                    });
                }
                catch(ex)
                {
                    console.error(ex);
                }
            });
        });
    }, []);

    useEffect(() => 
    {
        microsoftTeams.settings.registerOnSaveHandler((saveEvent) => 
        {
            setSubmitting(true);

            const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
            const pageUrl = `/connector/config`
            const entityId = `${Vars.ENTITY_ID_PREFIX}-${context.channelId}-${selectedSite.id}`;

            const settings: any = {
                entityId: entityId,
                configName: selectedSite.displayName,
                contentUrl: `${baseUrl}${pageUrl}?entity={entityId}&loginHint={loginHint}`,
            };

            microsoftTeams.settings.setSettings(settings);

            microsoftTeams.settings.getSettings(async (settings) => 
            {
                try
                {
                    // Save webhook URL in DB
                    await DataService.current().addConnection({
                        entityId: entityId,
                        siteUrl: selectedSite.webUrl,
                        tenantId: context.tid,
                        webhookUrl: settings['webhookUrl'],
                        graphRefreshToken: tokens.graphRefresh,
                        spRefreshToken: tokens.spRefresh
                    });

                    saveEvent.notifySuccess();
                }
                catch(ex)
                {
                    console.error(ex);
                    saveEvent.notifyFailure('Failed to add connection');
                    setSubmitting(false);
                }
            });
        });

        microsoftTeams.settings.registerOnRemoveHandler((removeEvent) => 
        {
            microsoftTeams.settings.getSettings(async (settings) => 
            {
                try
                {
                    await DataService.current().removeConnection(settings.entityId);
                    removeEvent.notifySuccess();
                }
                catch(ex)
                {
                    console.error(ex);
                    removeEvent.notifyFailure('Failed to remove connection');
                }
            });
        });

    }, [context, tokens, selectedSite]);

    useEffect(() => 
    {
        if(selectedSite && tokens)
        {
            const verifyListExists = async () => 
            {
                const client = new SPRest({
                    headers: { 'authorization': `bearer ${tokens.sp}` }
                }, selectedSite.webUrl);
    
                try
                {
                    const list = await client.web.lists.getByTitle('Vacation Requests').get();
                    microsoftTeams.settings.setValidityState(true);
                    setErrorMessage(null);
                }
                catch(ex)
                {
                    microsoftTeams.settings.setValidityState(false);
                    setErrorMessage('This site has not been configured for the Vacation Reminders connector. Please choose a different site.');
                }
            };

            verifyListExists();
        }
        else
        {
            setErrorMessage(null);
            microsoftTeams.settings.setValidityState(false);
        }

    }, [selectedSite, tokens]);

    const onSiteChange = (items: ITag[]) => 
    {
        if(items && items.length > 0)
        {
            const site = sites.find(s => s.id === items[0].key);
            if(site)
                setSelectedSite(site);
        }
        else
        {
            setSelectedSite(null);
        }
    };

    const options: ITag[] = sites.map(s => { return { key: s.id, name: s.displayName } });

    const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
        if(!tagList || !tagList.length || tagList.length === 0)
            return false;
        else
            return tagList.some(compareTag => compareTag.key === tag.key);
    }

    const filterSuggestedTags = (filterText: string, tagList: ITag[]) => {
        if(filterText)
            return options.filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList));
        else
            return [];
    };

    if(loading === false)
    {
        return (
            <div className="page-connector-configuration">
                <Stack className="config-stack">
                    <div className="config-description">
                        <Text>The Vacation Reminder connector reminds group members of upcoming vacations by sending notifications straight to a Teams channel. Select a SharePoint site below to connect.</Text>
                    </div>

                    {
                        !editMessage &&
                        <>
                            <Label htmlFor="sharepoint-site-select">Connect To</Label>
                            <TagPicker 
                                onResolveSuggestions={filterSuggestedTags}
                                getTextFromItem={(item: ITag) => item.name}
                                pickerSuggestionsProps={{ suggestionsHeaderText: '', noResultsFoundText: 'No sites found' }}
                                itemLimit={1}
                                disabled={submitting}
                                onChange={onSiteChange}
                                inputProps={{
                                    placeholder: "Search SharePoint sites",
                                    id: "sharepoint-site-select"
                                }}
                            />
                        </>
                    }

                    {
                        errorMessage &&
                        <Text style={{ color: 'red' }}>{errorMessage}</Text>
                    }

                    {
                        editMessage &&
                        <Label>{editMessage}</Label>
                    }

                </Stack>
            </div>
        );
    }
    else
    {
        return (
            <div className="page-connector-configuration">
                <Stack className="login-stack">
                    <Spinner size={SpinnerSize.large} />
                    <Text variant="large" style={{ marginTop: '5px' }}>Loading</Text>
                </Stack>
            </div>
        );
    }
};

export default ConnectorConfigurationPage;

// Placeholder URL values
// {entityId}: The ID you supplied for the item in this tab when first configuring the tab.
// {subEntityId}: The ID you supplied when generating a deep link for a specific item within this tab. This must be used to restore to a specific state within an entity; for example, scrolling to or activating a specific piece of content.
// {loginHint}: A value suitable as a login hint for AAD. This is usually the login name of the current user in their home tenant.
// {userPrincipalName}: The User Principal Name of the current user in the current tenant.
// {userObjectId}: The AAD object ID of the current user in the current tenant.
// {theme}: The current user interface (UI) theme such as default, dark, or contrast.
// {groupId}: The ID of the Office 365 group in which the tab resides.
// {tid}: The AAD tenant ID of the current user.
// {locale}: The current locale of the user formatted as languageId-countryId. For example, en-us.
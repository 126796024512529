import { Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
// import { Flex, Loader, Text } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { TeamsAuth } from "../../utils/TeamsAuth";
import './Login.scss';

export interface CallbackProps 
{

}

export const Callback: React.FunctionComponent<CallbackProps> = (props) => 
{
    const [ errorCode, setErrorCode ] = useState<string>(null);
    const [ errorMessage, setErrorMessage ] = useState<string>(null);

    useEffect(() => 
    {
        microsoftTeams.initialize();
        microsoftTeams.getContext(async (context) => 
        {
            let params: URLSearchParams = new URLSearchParams();
            if(window.location.hash.length > 0)
                params = new URLSearchParams(window.location.hash.substring(1))
            else if(window.location.search.length > 0)
                params = new URLSearchParams(window.location.search.substring(1));
            else
            {
                setErrorCode('missing_code');
                setErrorMessage('No callback code found.');
                return;
            }

            if(params.get('error'))
            {
                setErrorCode(params.get('error'));
                setErrorMessage(params.get('error_description'))
            }
            else if(params.get('state') !== sessionStorage.getItem('auth-state'))
            {
                setErrorCode('state_mismatch');
                setErrorMessage('The callback state does not match the stored state. Please try logging in again.');
                microsoftTeams.authentication.notifyFailure('state_mismatch: The callback state does not match the stored state. Please try logging in again.');
            }
            else
            {
                // Proof Key for Code Exchange authentication (PKCE) callback
                if(params.get('code'))
                {
                    const code = params.get('code');
                    const codeVerifier = sessionStorage.getItem('auth-code-verifier');
                    const token = await TeamsAuth.exchangeCodeForToken(code, codeVerifier, context.tid);

                    microsoftTeams.authentication.notifySuccess(token.access_token);
                }
                // Consent page callback
                else
                {
                    microsoftTeams.authentication.notifySuccess(null);
                }
            }
        });
    }, []);

    return (
        <div className="page-callback">
            {
                errorCode && (
                    <>
                        <Text variant="large">{errorCode}</Text>
                        <Text variant="medium">{errorMessage}</Text>
                        {/* <Text size="large" content={errorCode} />
                        <Text size="medium" content={errorMessage} /> */}
                    </>
                )
            }
            {
                !errorCode &&
                <Stack className="login-stack">
                    <Spinner size={SpinnerSize.large} />
                    <Text variant="large">Signing in</Text>
                </Stack>
                // <Flex className="login-stack" hAlign="center">
                //     <Loader label="Signing in" />
                // </Flex>
            }
        </div>
    );
};

export default Callback;

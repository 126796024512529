import { Client } from "@microsoft/microsoft-graph-client";
import { Site } from "@microsoft/microsoft-graph-types";

export default class GraphClient
{
    private static _current: GraphClient = null;
    public static current() 
    {
        return this._current;
    }

    public static initialize(accessToken: string)
    {
        this._current = new GraphClient(accessToken);
    }

    private client: Client = null;
    constructor(accessToken)
    {
        this.client = Client.init({
            authProvider: done => done(null, accessToken)
        });
    }

    public async sites(searchQuery: string = ''): Promise<Site[]>
    {
        const response = await this.client.api(`/sites?search=${searchQuery}`).get();

        const sortedSites = response.value.sort((a: Site, b: Site) => {
            if(a.displayName < b.displayName) return -1;
            else if(a.displayName > b.displayName) return 1;
            else return 0;
        });

        return sortedSites;
    }
}
import React, { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { TeamsAuth } from "../utils/TeamsAuth";
import { Text } from "@fluentui/react";

export interface InstructionsTabProps
{

}

export const InstructionsTab: React.FunctionComponent<InstructionsTabProps> = (props) => 
{
    const [ context, setContext ] = useState<microsoftTeams.Context>(null);

    useEffect(() => 
    {
        microsoftTeams.initialize();
        microsoftTeams.getContext((ctx) => {
            setContext(ctx);
            microsoftTeams.appInitialization.notifySuccess();
        });

    }, []);

    return (
        <div>
            <Text>Setup Instructions</Text>
            
            {/* <div>{JSON.stringify(context)}</div> */}
        </div>
    );
}

export default InstructionsTab;